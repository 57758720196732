import React from 'react';
import { Flex, Box, Text, Image } from '@chakra-ui/core';
import { colors } from '@theme';
import { H2, H4 } from '@typography';
import { cloudfrontBaseUrl } from '../../lib/consts';
import TextWithImageRow from '../zeta/TextWithImageRow';

const JointKeyFeatures = () => {
  const jointFeatures1 = `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-features1-new.jpg`;
  const jointFeatures2 = `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-features2.jpg`;
  const jointFeatures3 = `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-features3.jpg`;
  const jointFeatures4 = `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-features4.jpg`;
  const jointFeaturesMobile1 = `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-features1-mobile.png`;
  const jointFeaturesMobile2 = `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-features2-mobile.png`;
  const jointFeaturesMobile3 = `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-features3-mobile.png`;
  const jointFeaturesMobile4 = `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-features4-mobile.png`;

  return (
    <Flex paddingBottom={[10, 24]} paddingX={[0, 16, 16, 8]} direction="column">
      <Flex
        justify="center"
        align="center"
        direction="column"
        textAlign="center"
        mb={[8]}
        px={[4, 0]}
      >
        <H2>Joint account key features</H2>
        <Flex mt="12px" justify="center">
          <H4 center lineHeight={['1.2']} color={colors.grayGray900}>
            All the features of our Personal Accounts,
            <br />
            layered with collaboration and communication tools.
          </H4>
        </Flex>
      </Flex>

      <TextWithImageRow
        tag="easy setup"
        title={`An account for every stage\nof your relationship`}
        body={`Whether you're dating, engaged or married, you can open an account in minutes.
      We'll even help you setup your shared finances effortlessly.`}
        image={jointFeatures1}
        imageOnRight={true}
        mobileImage={jointFeaturesMobile1}
        mobileBackground={'#D0EFF6'}
        fullWidthMobileBackground={true}
        smallerText={true}
      />

      <TextWithImageRow
        tag="envelopes"
        title={`Master your money as a team`}
        body={`Use our envelope system to seamlessly manage your shared bills and goals. Stay on the same page about what's coming due, what's already paid or what needs more funds.`}
        imageOnRight={false}
        image={jointFeatures2}
        mobileImage={jointFeaturesMobile2}
        mobileBackground={'#D0EFF6'}
        smallerText={true}
      />

      <TextWithImageRow
        tag="messaging"
        title={`Stay in sync on your shared finances`}
        body={`In-app messaging keeps you in sync with your team and gives you access to your Concierge. Stay informed through transaction notifications, spending trackers, and more.`}
        imageOnRight={true}
        image={jointFeatures3}
        mobileImage={jointFeaturesMobile3}
        mobileBackground={'#D0EFF6'}
        smallerText={true}
      />

      <TextWithImageRow
        tag="HOUSEHOLDS"
        title={`Your shared financial life in one place`}
        body={`Whether or not you fully combine your finances, Zeta adapts to you so you can manage your money your way. Open a personal account or a joint account and enjoy instant transfers between those accounts.`}
        imageOnRight={false}
        image={jointFeatures4}
        mobileImage={jointFeaturesMobile4}
        mobileBackground={'#D0EFF6'}
        smallerText={true}
      />
    </Flex>
  );
};

export default JointKeyFeatures;
