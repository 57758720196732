import React from 'react';
import { useStoreActions } from 'easy-peasy';
import { Flex, Text, Box, Image, Link } from '@chakra-ui/core';
import theme, { colors } from '@theme';
import styled from 'styled-components';

import ContentContainer from '../components/ContentContainer';
import { NavButton } from '@zeta';
import { APP_CONTENT_SMALL_DESKTOP_WIDTH } from '../styles/sizes';
import { toRoundedDollars } from '../lib/toDollars';
import canUseDOM from '@lib/canUseDOM';

const Navigation = ({ signupUrl = '', referralAmount = 2500 }) => {
  const NavLink = props => {
    const { text, location, mobile = false, ...rest } = props;
    return (
      <Link className="nav-link" noNewTab href={location} {...rest}>
        <Text
          as="span"
          fontWeight="semibold"
          color={homeNav ? colors.primary : lightMode ? colors.primary : colors.iceberg}
          fontSize="sm"
        >
          {text}
        </Text>
      </Link>
    );
  };

  const LogoLink = ({ mobile = false }) => {
    return (
      <Link className="nav-link" noNewTab to="/">
        <Flex ml={[0, -8]} direction="column" justify="center">
          <Image
            alt="Zeta"
            src={'https://d142whjg6dsdg2.cloudfront.net/logos/zeta-logo-lightmode.svg'}
          />
        </Flex>
      </Link>
    );
  };

  const NavContainer = ({ children, mobile = false }) => {
    return (
      <Box
        as="nav"
        position={mobile ? undefined : 'fixed'}
        backgroundColor={colors.iceberg}
        style={{ width: '100%', zIndex: 1 }}
        pb={mobile ? 6 : 4}
      >
        {children}
      </Box>
    );
  };

  const GetStartedBtn = ({ isMobile = false }) => {
    const goSignup = () => {
      if (canUseDOM) {
        window.location.href = signupUrl;
      }
    };
    return (
      <Flex style={{ marginLeft: isMobile ? undefined : '2rem' }}>
        <NavButton
          variant="primary"
          onClick={goSignup}
          text={`Get my ${toRoundedDollars(referralAmount)}`}
          style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
        />
      </Flex>
    );
  };

  const LargeNav = () => {
    return (
      <NavContainer>
        <ContentContainer pt={4}>
          <Flex direction="row" justify="space-between">
            <Flex justify="flex-start" align="center">
              <LogoLink />
            </Flex>
            <Flex>
              <Flex align="center" justify="flex-end">
                <GetStartedBtn />
              </Flex>
            </Flex>
          </Flex>
        </ContentContainer>
      </NavContainer>
    );
  };

  const SmallNav = () => {
    return (
      <NavContainer mobile>
        <Flex textAlign="center" align="center" direction="column" px={4} pt={4}>
          <LogoLink mobile />
          <Flex justifyContent="space-between" width="100%" pt={4}>
            &nbsp;
          </Flex>
          {/* <Flex direction="column" mt={6}>
            <GetStartedBtn isMobile />
          </Flex> */}
        </Flex>
      </NavContainer>
    );
  };

  const DesktopOnly = styled.div`
    @media (max-width: ${APP_CONTENT_SMALL_DESKTOP_WIDTH}) {
      display: none;
    }
  `;
  const MobileOnly = styled.div`
    @media (min-width: ${APP_CONTENT_SMALL_DESKTOP_WIDTH}) {
      display: none;
    }
  `;
  return (
    <>
      <DesktopOnly>
        <LargeNav />
      </DesktopOnly>
      <MobileOnly>
        <SmallNav />
      </MobileOnly>
    </>
  );
};

export default Navigation;
